import { graphql, useStaticQuery } from 'gatsby';
import {
    ContactPageQuery,
    ContactPageQuery_sanityContactPage as ContactPage,
} from '../__generated__/ContactPageQuery';

export default function useContactPageQuery(): ContactPage {
    const query: ContactPageQuery = useStaticQuery(graphql`
        query ContactPageQuery {
            sanityContactPage(_id: { eq: "contactPage" }) {
                pageMetadata {
                    ...PageMetadataFragment
                }
                banner {
                    ...BannerFragment
                }
                intro: _rawIntro
            }
        }
    `);

    if (query.sanityContactPage) {
        return query.sanityContactPage;
    }

    throw new Error('Unfound ContactPage');
}
