import React from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import FieldInput from './FieldInput';
import FieldTextarea from './FieldTextarea';
import { Viewport, CONTACT_EMAIL, CONTACT_FORM_NAME } from '../Settings';

const Form = styled.form`
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-row-gap: var(--size6);

    @media (max-width: ${Viewport.Mobile}px) {
        padding: 0 var(--sizeGutters);
        grid-template-columns: 100%;
    }
`;

const Title = styled.div`
    grid-column: 4 / span 14;

    font-size: var(--size5);
    line-height: 1.35;
    color: var(--foregroundColor);

    @media (max-width: ${Viewport.Mobile}px) {
        grid-column: 1 / span 1;
    }
`;

const Inner = styled.div`
    grid-column: 4 / span 14;

    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--size4);

    @media (max-width: ${Viewport.Mobile}px) {
        grid-column: 1 / span 1;
        // grid-row-gap: var(--size1);
    }
`;

const SubmitButton = styled.button.attrs({ type: 'submit' })`
    text-transform: uppercase;
    font-size: var(--size2);
    color: var(--white);
    border: 2px solid var(--white);
    padding: var(--size2);
    letter-spacing: var(--letterSpacingExtra);
`;

const SuccessMessage = styled.div`
    line-height: var(--lineHeightMax);
    color: var(--white);

    p {
        &:nth-child(n + 2) {
            // margin-top: var(--size1);
        }
    }
`;

interface ContactFormFields {
    fullName: string;
    email: string;
    phone: string;
    message: string;
}

function ContactForm(): React.ReactElement {
    const [submitting, setSubmitting] = React.useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(
        false
    );

    const successMessageRef = React.useRef<HTMLDivElement | null>(null);

    const { register, handleSubmit, reset } = useForm<ContactFormFields>({
        defaultValues: {
            fullName: '',
            email: '',
            phone: '',
            message: '',
        },
    });

    const onSubmit = async (values: ContactFormFields): Promise<void> => {
        if (submitting) {
            return;
        }
        setSubmitting(true);
        setShowSuccessMessage(false);

        window
            .fetch(window.location.pathname, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: queryString.stringify({
                    ...values,
                    'form-name': CONTACT_FORM_NAME,
                    subject: `${window.location.hostname} - Contact Form Enquiry`,
                }),
            })
            .then((response): void => {
                if (response.status === 200) {
                    reset();
                    setShowSuccessMessage(true);
                    const successMessage = successMessageRef.current;
                    if (successMessage) {
                        successMessage.scrollIntoView({
                            behavior: 'smooth',
                        });
                    }
                } else {
                    alert(
                        `An unexpected error occurred, please try again later or contact us directly on ${CONTACT_EMAIL}`
                    );
                }
            })
            .finally((): void => {
                setSubmitting(false);
            });
    };

    return (
        <Form
            onSubmit={handleSubmit(onSubmit)}
            data-netlify="true"
            name={CONTACT_FORM_NAME}
        >
            <Title>Contact form</Title>
            <Inner>
                <FieldInput
                    label="Full Name"
                    type="text"
                    name="fullName"
                    ref={register}
                    required
                />
                <FieldInput
                    label="Email Address"
                    type="email"
                    name="email"
                    ref={register}
                    required
                />
                <FieldInput
                    label="Phone Number"
                    type="phone"
                    name="phone"
                    ref={register}
                />
                <FieldTextarea
                    label="Your Message"
                    name="message"
                    rows={4}
                    ref={register}
                    required
                />
                <div>
                    <SubmitButton>Submit</SubmitButton>
                </div>

                <SuccessMessage
                    ref={successMessageRef}
                    hidden={showSuccessMessage ? undefined : true}
                >
                    <p>Thanks for your message.</p>
                    <p>We will get back to as soon as possible.</p>
                </SuccessMessage>
            </Inner>
        </Form>
    );
}

export default React.memo(ContactForm);
