import React from 'react';
import styled from 'styled-components';
import useUuid from '../utils/useUuid';
import Field, { inputStyles } from './Field';

const Input = styled.input`
    ${inputStyles};
`;

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
}

function FieldInput(
    { label, ...inputProps }: Props,
    ref: React.Ref<HTMLInputElement>
): React.ReactElement {
    const id = useUuid();
    return (
        <Field label={label} htmlFor={id}>
            <Input {...inputProps} ref={ref} id={id} />
        </Field>
    );
}

export default React.forwardRef(FieldInput);
