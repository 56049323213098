import React from 'react';
import styled from 'styled-components';
import { LightTheme, DarkTheme } from '../components/Theme';
import Navbar from '../components/Navbar';
import Banner from '../components/Banner';
import Intro from '../components/Intro';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Metadata from '../components/Metadata';
import useContactPage from '../utils/useContactPageQuery';

const Section = styled.div`
    display: grid;
    grid-template-columns: 100%;

    &[data-section='banner'] {
        padding-bottom: var(--sectionSpacing10);
    }

    &[data-section='intro'] {
        grid-row-gap: var(--sectionSpacing11);
        padding: var(--sectionSpacing11) 0;
    }

    &[data-section='ctas'] {
        grid-row-gap: var(--sectionSpacing10);
        padding-top: var(--sectionSpacing10);
    }
`;

function Contact(): React.ReactElement {
    const { banner, intro, pageMetadata } = useContactPage();

    return (
        <React.Fragment>
            {pageMetadata && <Metadata pageMetadata={pageMetadata} />}
            <LightTheme>
                <Section data-section="banner">
                    <Navbar />
                    {banner && <Banner {...banner} />}
                </Section>
            </LightTheme>
            <DarkTheme>
                <Section data-section="intro">
                    {intro && <Intro contentRaw={intro} />}
                    <ContactForm />
                </Section>
            </DarkTheme>

            <LightTheme>
                <Footer />
            </LightTheme>
        </React.Fragment>
    );
}

export default React.memo(Contact);
