import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--size1);
`;

const Label = styled.label`
    font-size: var(--size2);
    line-height: 2.88;
    letter-spacing: var(--letterSpacingExtra);
    text-transform: uppercase;
    text-align: left;
    color: var(--foregroundColor);
`;

export const inputStyles = css`
    font-size: var(--size3);
    line-height: var(--lineHeightMed);
    padding: 0.3em;
    background: none;
    border: 2px solid var(--white);
    color: var(--foregroundColor);
`;

function Field({
    label,
    htmlFor,
    children,
}: {
    label: string;
    htmlFor: string;
    children: React.ReactNode;
}): React.ReactElement {
    return (
        <Container>
            <Label htmlFor={htmlFor}>{label}</Label>
            {children}
        </Container>
    );
}

export default Field;
