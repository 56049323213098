import React from 'react';
import styled from 'styled-components';
import useUuid from '../utils/useUuid';
import Field, { inputStyles } from './Field';

const Textarea = styled.textarea`
    ${inputStyles};
`;

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
    label: string;
}

function FieldTextarea(
    { label, ...textareaProps }: Props,
    ref: React.Ref<HTMLTextAreaElement>
): React.ReactElement {
    const id = useUuid();
    return (
        <Field label={label} htmlFor={id}>
            <Textarea {...textareaProps} ref={ref} id={id} />
        </Field>
    );
}

export default React.forwardRef(FieldTextarea);
